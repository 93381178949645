<template>
<div class="about">
  <v-container>

    <div class="title-div-alert-index">
      <h2>
        Alerts Index
      </h2>
      <v-divider></v-divider>
    </div>

    <v-data-table
      :headers="headers"
      :items="alertsIndexData"
      :loading="isLoading"
      :items-per-page-options="[43]"
      class="alert-index-table"
      :footer-props="{
        prevIcon: 'chevron_left',
        nextIcon: 'chevron_right',
        itemsPerPageOptions: [43]
      }"
    >
      <template v-slot:item="props">
        <tr>
          <td class="respect-new-line" :style="generateStyle(props.item['ALERT NAME'])">{{ props.item['ALERT NAME'] }}</td>
          <td class="respect-new-line">{{ props.item['DEFINITION AND TRIGGERS'] }}</td>
          <td class="respect-new-line">{{ props.item['ASSOCIATED ACTION'] }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</div>
</template>

<script>
import { mapActions } from 'vuex'
import store from '../../store/index.js'
import { alertings } from '../../store/modules/alertings.js'
import { baseViewMixin } from '@/mixins/baseViewMixin'

// load the store module dynamically only when needed
if (!store.state.alertings) store.registerModule('alertings', alertings)

export default {
  name: 'AlertingsIndex',
  props: [],
  components: {

  },
  mixins: [baseViewMixin],
  data: function () {
    return {
      alertsIndexData: [],
      headers: [
        {
          text: 'ALERT NAME',
          align: 'left',
          sortable: false,
          value: ''
        },
        {
          text: 'DEFINITION AND TRIGGERS',
          align: 'left',
          sortable: false,
          value: ''
        },
        {
          text: 'ASSOCIATED ACTION',
          align: 'left',
          sortable: false,
          value: ''
        }
      ],
      isLoading: false
    }
  },
  created: async function () {

  },
  mounted: async function () {
    this.onMounted()
    this.isLoading = true
    let response = await this.getAlertsIndexData()

    this.alertsIndexData = response['data']['list']

    this.isLoading = false
  },
  methods: {
    ...mapActions(['getAlertsIndexData']),
    /**
     * generate a style in function of the nomenclature value by finding the error type with regex
     * @param nomValue {string}
     * @returns {string}
     */
    generateStyle: function (nomValue) {
      let style = ''

      const typeOfLineRegex = {
        simple: {
          regex: /[0-9]\./
        },
        medium: {
          regex: /[0-9]\.[0-9]\./
        },
        full: {
          regex: /[0-9]\.[0-9]\.[0-9]/
        }
      }

      const errorTypeRegex = {
        realTimeAlert: {
          regex: /1\.[0-9]\.[0-9]/,
          style: 'color: red'
        },
        dailyAlert: {
          regex: /2\.[0-9]\.[0-9]/,
          style: 'color: orange'
        },
        weeklyAlert: {
          regex: /3\.[0-9]\.[0-9]/,
          style: 'color: blue'
        }
      }

      if (typeOfLineRegex.full.regex.test(nomValue)) {
        for (let i in errorTypeRegex) {
          if (errorTypeRegex[i].regex.test(nomValue)) {
            style = errorTypeRegex[i].style
            break
          }
        }
      } else {
        switch (nomValue.trim()[0]) {
          case '1':
            style = 'color: red; font-weight: bold;'
            break
          case '2':
            style = 'color: orange; font-weight: bold;'
            break
          case '3':
            style = 'color : blue; font-weight: bold;'
            break
          default:
            style = ''
        }
      }

      return style
    }
  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
  .title-div-alert-index {
    margin-bottom: 3em;
  }

  .title-div-alert-index h2 {
    padding: 1em;
  }

  .alert-index-table td {
    font-size: 10px !important;
  }

  @media only screen
  and (min-width : 1824px) {
    .alert-index-table td {
      font-size: 14px !important;
    }
  }

</style>
